<template>
  
    
   
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="5">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Change Password</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="user.oldpassword"
                    :rules="[v => !!v || 'Old Password is required']"
                    prepend-icon=" mdi-account-key"
                    placeholder="Old Password"
                    :disabled="disabled == 1 ? true : false"
                    clearable
                    :append-icon="value2 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value2 = !value2)"
                    :type="value2?'password':'text'"
                    required
                  ></v-text-field>
                  <div class="error-message" v-if="error">{{error_message}}</div>
                  <v-layout align-center justify-center v-if="hideen">
                    <v-btn class="mr-4" color="primary" @click="validateoldpassword">Check Password</v-btn>
                  </v-layout>
                  <v-text-field
                    v-if="!hideen"
                    v-model="user.password"
                    :rules="[v => !!v || 'Password is required']"
                    prepend-icon=" mdi-account-key"
                    placeholder="Password"
                    clearable
                    :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value = !value)"
                    :type="value ? 'password' : 'text'"
                    hint="In 8 digit password, Atleast 1 number, special characters and small / capital letters required."
                    required
                  ></v-text-field>
                  <v-text-field
                    v-if="!hideen"
                    v-model="user.confirmpassword"
                    :rules="[v => !!v || 'Confirm Password is required']"
                    prepend-icon=" mdi-account-key"
                    placeholder="Confirm Password"
                    clearable
                    :append-icon="value1 ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (value1 = !value1)"
                    :type="value1 ? 'password' : 'text'"
                    hint="In 8 digit password, Atleast 1 number, special characters and small/ capital letters required."
                    required
                  ></v-text-field>
                  <div class="error-message" v-if="error1">{{error_message1}}</div>
                  <v-layout align-center justify-center v-if="!hideen">
                    <v-btn class="mr-4" color="primary" @click="setpassword">Change Password</v-btn>
                  </v-layout>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

</template>
<script>
import axios from "axios";
import epsHeader from "../shared/Header";
export default {
  components: {
    epsHeader: epsHeader
  },
  data() {
    return {
      user: {
        uid: "",
        oldpassword: "",
        password: "",
        confirmpassword: ""
      },
      value: String,
      value1: String,
      value2: String,
      hideen: true,
      disabled: 0,
      message: "",
      error: false,
      error_message: "",
      error1: false,
      error_message1: ""
    };
  },
  methods: {
    validateoldpassword() {
      if (this.perormoldPasswordFormValidation()) {
        //console.log("I am in If block :: " + this.user.oldpassword);
        this.user.dns = window.location.hostname;
        this.user.uid = localStorage.getItem("EPS-uid");
        //console.log("this.user.uid :: " + this.user.uid);
        axios
          .post("/login/checkpassword", this.user)
          .then(res => {
            //window.console.log("res" + res.data.msg);
            if (res.data.msg == "200") {
              this.hideen = false;
              this.disabled = (this.disabled + 1) % 2;
              this.error_message="";
            } else {
              //console.log("error :: ");
              this.message = "Password not Match!!!";
              this.error = true;
              this.error_message = "Old Password does not match!";
            }
          })
          .catch(error => {
            window.console.log(error);
            this.message = "Something went wrong";
          });
      } else {
        //console.log("I am in else block :: ");
        this.error = true;
        this.error_message = "Old Password does not match!";
      }
    },
    setpassword() {
      if (
        this.user.password == this.user.confirmpassword &&
        this.user.password.length > 0 &&
        this.user.confirmpassword.length > 0
      ) {
        if (this.perormChangePasswordFormValidation()) {
        //   console.log(
        //     "I am in If block :: " +
        //       this.user.password +
        //       " :: " +
        //       this.user.confirmpassword
        //   );
          this.user.dns = window.location.hostname;
          this.user.uid = localStorage.getItem("EPS-uid");
          //console.log("this.user.uid :: " + this.user.uid);
          axios
            .post("/login/changepassword", this.user)
            .then(res => {
              //window.console.log("res" + res.data.msg);
              if (res.data.msg == "200") {
                this.$router.push("/home");
                //this.$router.push("/");
              } else {
                this.message = "Password not Match!!!";
                this.error1 = true;
                this.error_message1 = "Password Not Match..!";
              }
            })
            .catch(error => {
              window.console.log(error);
              this.message = "Something went wrong";
            });
        } else {
          //console.log("I am in else block :: " + this.user.password);
          this.error1 = true;
          this.error_message1 = "Password does not match!";
        }
      } else {
        //console.log("I am in else block :: " + this.user.password);
        this.error1 = true;
        this.error_message1 = "Password does not match!";
      }
    },
    perormChangePasswordFormValidation() {
      if(this.user.password=="" && this.user.confirmpassword=="")
      {
        return false;
      }
      else{
       return true;
      }
    },
    perormoldPasswordFormValidation() {
      if(this.user.oldpassword=="")
      {
        return false;
      }
      else{
       return true;
      }
    },
    showPassword() {
        if(this.type === 'password') {
            this.type = 'text';
            this.btnText = 'Hide Password';
        } else {
            this.type = 'password';
            this.btnText = 'Show Password';
        }
    }
  },
  mounted() {
    this.message=localStorage.getItem('login-page-msg')
  }
};
</script>
<style scoped>
  .sidebar-link {
    text-decoration: none;
  }
  .sidebar-link-item:hover {
    background-color: rgb(181, 186, 190) !important;
  }
  .pull-right{
      position: relative;
      text-align: center;
  }
  .error-message {
    color : red;
    font-size : 14px;
    font-weight : 900;
    padding-left : 7%;
  }
</style>